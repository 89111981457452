/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import axios from 'axios'
import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import qs from 'qs'
import type {
  AppOutputResponse,
  AppRunResponse,
  AppRunsListResponse,
  GetProjectsProjectIdRunsParams,
  JSONAPIErrorResponse,
  ServiceCloneRunInput,
  ServiceCreateRunInput,
  ServiceGenerateVisualisationInput,
  ServiceRunQAPassInput,
  ServiceRunQARejectInput,
  ServiceUpdateRunInput
} from './types'




/**
 * Get a list of project runs.
 * @summary Get a list of project runs
 */
export const getProjectsProjectIdRuns = (
    projectId: string,
    params?: GetProjectsProjectIdRunsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AppRunsListResponse>> => {
    
    return axios.get(
      `/projects/${projectId}/runs`,{
    ...options,
        params: {...params, ...options?.params},
        paramsSerializer: (params) => qs.stringify(params, {"arrayFormat":"comma"}),}
    );
  }


export const getGetProjectsProjectIdRunsQueryKey = (projectId: string,
    params?: GetProjectsProjectIdRunsParams,) => {
    return [`/projects/${projectId}/runs`, ...(params ? [params]: [])] as const;
    }

    
export const getGetProjectsProjectIdRunsQueryOptions = <TData = Awaited<ReturnType<typeof getProjectsProjectIdRuns>>, TError = AxiosError<JSONAPIErrorResponse>>(projectId: string,
    params?: GetProjectsProjectIdRunsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectsProjectIdRuns>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProjectsProjectIdRunsQueryKey(projectId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectsProjectIdRuns>>> = ({ signal }) => getProjectsProjectIdRuns(projectId,params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProjectsProjectIdRuns>>, TError, TData> & { queryKey: QueryKey }
}

export type GetProjectsProjectIdRunsQueryResult = NonNullable<Awaited<ReturnType<typeof getProjectsProjectIdRuns>>>
export type GetProjectsProjectIdRunsQueryError = AxiosError<JSONAPIErrorResponse>


export function useGetProjectsProjectIdRuns<TData = Awaited<ReturnType<typeof getProjectsProjectIdRuns>>, TError = AxiosError<JSONAPIErrorResponse>>(
 projectId: string,
    params: undefined |  GetProjectsProjectIdRunsParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectsProjectIdRuns>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getProjectsProjectIdRuns>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetProjectsProjectIdRuns<TData = Awaited<ReturnType<typeof getProjectsProjectIdRuns>>, TError = AxiosError<JSONAPIErrorResponse>>(
 projectId: string,
    params?: GetProjectsProjectIdRunsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectsProjectIdRuns>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getProjectsProjectIdRuns>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetProjectsProjectIdRuns<TData = Awaited<ReturnType<typeof getProjectsProjectIdRuns>>, TError = AxiosError<JSONAPIErrorResponse>>(
 projectId: string,
    params?: GetProjectsProjectIdRunsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectsProjectIdRuns>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get a list of project runs
 */

export function useGetProjectsProjectIdRuns<TData = Awaited<ReturnType<typeof getProjectsProjectIdRuns>>, TError = AxiosError<JSONAPIErrorResponse>>(
 projectId: string,
    params?: GetProjectsProjectIdRunsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectsProjectIdRuns>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetProjectsProjectIdRunsQueryOptions(projectId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Create a new project run.
 * @deprecated
 * @summary Create a new project run
 */
export const postProjectsProjectIdRuns = (
    projectId: string,
    serviceCreateRunInput: ServiceCreateRunInput, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AppRunResponse>> => {
    
    return axios.post(
      `/projects/${projectId}/runs`,
      serviceCreateRunInput,options
    );
  }



export const getPostProjectsProjectIdRunsMutationOptions = <TError = AxiosError<JSONAPIErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProjectsProjectIdRuns>>, TError,{projectId: string;data: ServiceCreateRunInput}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postProjectsProjectIdRuns>>, TError,{projectId: string;data: ServiceCreateRunInput}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postProjectsProjectIdRuns>>, {projectId: string;data: ServiceCreateRunInput}> = (props) => {
          const {projectId,data} = props ?? {};

          return  postProjectsProjectIdRuns(projectId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostProjectsProjectIdRunsMutationResult = NonNullable<Awaited<ReturnType<typeof postProjectsProjectIdRuns>>>
    export type PostProjectsProjectIdRunsMutationBody = ServiceCreateRunInput
    export type PostProjectsProjectIdRunsMutationError = AxiosError<JSONAPIErrorResponse>

    /**
 * @deprecated
 * @summary Create a new project run
 */
export const usePostProjectsProjectIdRuns = <TError = AxiosError<JSONAPIErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProjectsProjectIdRuns>>, TError,{projectId: string;data: ServiceCreateRunInput}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postProjectsProjectIdRuns>>,
        TError,
        {projectId: string;data: ServiceCreateRunInput},
        TContext
      > => {

      const mutationOptions = getPostProjectsProjectIdRunsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Archives a specific project run into cold storage.
 * @summary Archive a specific project run
 */
export const deleteProjectsProjectIdRunsRunId = (
    projectId: string,
    runId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AppRunResponse>> => {
    
    return axios.delete(
      `/projects/${projectId}/runs/${runId}`,options
    );
  }



export const getDeleteProjectsProjectIdRunsRunIdMutationOptions = <TError = AxiosError<JSONAPIErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteProjectsProjectIdRunsRunId>>, TError,{projectId: string;runId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteProjectsProjectIdRunsRunId>>, TError,{projectId: string;runId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteProjectsProjectIdRunsRunId>>, {projectId: string;runId: string}> = (props) => {
          const {projectId,runId} = props ?? {};

          return  deleteProjectsProjectIdRunsRunId(projectId,runId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteProjectsProjectIdRunsRunIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteProjectsProjectIdRunsRunId>>>
    
    export type DeleteProjectsProjectIdRunsRunIdMutationError = AxiosError<JSONAPIErrorResponse>

    /**
 * @summary Archive a specific project run
 */
export const useDeleteProjectsProjectIdRunsRunId = <TError = AxiosError<JSONAPIErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteProjectsProjectIdRunsRunId>>, TError,{projectId: string;runId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteProjectsProjectIdRunsRunId>>,
        TError,
        {projectId: string;runId: string},
        TContext
      > => {

      const mutationOptions = getDeleteProjectsProjectIdRunsRunIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get a specific project run.
 * @summary Get a specific project run
 */
export const getProjectsProjectIdRunsRunId = (
    projectId: string,
    runId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AppRunResponse>> => {
    
    return axios.get(
      `/projects/${projectId}/runs/${runId}`,options
    );
  }


export const getGetProjectsProjectIdRunsRunIdQueryKey = (projectId: string,
    runId: string,) => {
    return [`/projects/${projectId}/runs/${runId}`] as const;
    }

    
export const getGetProjectsProjectIdRunsRunIdQueryOptions = <TData = Awaited<ReturnType<typeof getProjectsProjectIdRunsRunId>>, TError = AxiosError<JSONAPIErrorResponse>>(projectId: string,
    runId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectsProjectIdRunsRunId>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProjectsProjectIdRunsRunIdQueryKey(projectId,runId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectsProjectIdRunsRunId>>> = ({ signal }) => getProjectsProjectIdRunsRunId(projectId,runId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(projectId && runId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProjectsProjectIdRunsRunId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetProjectsProjectIdRunsRunIdQueryResult = NonNullable<Awaited<ReturnType<typeof getProjectsProjectIdRunsRunId>>>
export type GetProjectsProjectIdRunsRunIdQueryError = AxiosError<JSONAPIErrorResponse>


export function useGetProjectsProjectIdRunsRunId<TData = Awaited<ReturnType<typeof getProjectsProjectIdRunsRunId>>, TError = AxiosError<JSONAPIErrorResponse>>(
 projectId: string,
    runId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectsProjectIdRunsRunId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getProjectsProjectIdRunsRunId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetProjectsProjectIdRunsRunId<TData = Awaited<ReturnType<typeof getProjectsProjectIdRunsRunId>>, TError = AxiosError<JSONAPIErrorResponse>>(
 projectId: string,
    runId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectsProjectIdRunsRunId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getProjectsProjectIdRunsRunId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetProjectsProjectIdRunsRunId<TData = Awaited<ReturnType<typeof getProjectsProjectIdRunsRunId>>, TError = AxiosError<JSONAPIErrorResponse>>(
 projectId: string,
    runId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectsProjectIdRunsRunId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get a specific project run
 */

export function useGetProjectsProjectIdRunsRunId<TData = Awaited<ReturnType<typeof getProjectsProjectIdRunsRunId>>, TError = AxiosError<JSONAPIErrorResponse>>(
 projectId: string,
    runId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectsProjectIdRunsRunId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetProjectsProjectIdRunsRunIdQueryOptions(projectId,runId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Update a specific project run.
 * @summary Update a specific project run
 */
export const patchProjectsProjectIdRunsRunId = (
    projectId: string,
    runId: string,
    serviceUpdateRunInput: ServiceUpdateRunInput, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AppRunResponse>> => {
    
    return axios.patch(
      `/projects/${projectId}/runs/${runId}`,
      serviceUpdateRunInput,options
    );
  }



export const getPatchProjectsProjectIdRunsRunIdMutationOptions = <TError = AxiosError<JSONAPIErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchProjectsProjectIdRunsRunId>>, TError,{projectId: string;runId: string;data: ServiceUpdateRunInput}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof patchProjectsProjectIdRunsRunId>>, TError,{projectId: string;runId: string;data: ServiceUpdateRunInput}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchProjectsProjectIdRunsRunId>>, {projectId: string;runId: string;data: ServiceUpdateRunInput}> = (props) => {
          const {projectId,runId,data} = props ?? {};

          return  patchProjectsProjectIdRunsRunId(projectId,runId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchProjectsProjectIdRunsRunIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchProjectsProjectIdRunsRunId>>>
    export type PatchProjectsProjectIdRunsRunIdMutationBody = ServiceUpdateRunInput
    export type PatchProjectsProjectIdRunsRunIdMutationError = AxiosError<JSONAPIErrorResponse>

    /**
 * @summary Update a specific project run
 */
export const usePatchProjectsProjectIdRunsRunId = <TError = AxiosError<JSONAPIErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchProjectsProjectIdRunsRunId>>, TError,{projectId: string;runId: string;data: ServiceUpdateRunInput}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof patchProjectsProjectIdRunsRunId>>,
        TError,
        {projectId: string;runId: string;data: ServiceUpdateRunInput},
        TContext
      > => {

      const mutationOptions = getPatchProjectsProjectIdRunsRunIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Clones a specific project run.
 * @summary Clone a specific project run
 */
export const postProjectsProjectIdRunsRunIdClone = (
    projectId: string,
    runId: string,
    serviceCloneRunInput: ServiceCloneRunInput, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AppRunResponse>> => {
    
    return axios.post(
      `/projects/${projectId}/runs/${runId}/clone`,
      serviceCloneRunInput,options
    );
  }



export const getPostProjectsProjectIdRunsRunIdCloneMutationOptions = <TError = AxiosError<JSONAPIErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdClone>>, TError,{projectId: string;runId: string;data: ServiceCloneRunInput}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdClone>>, TError,{projectId: string;runId: string;data: ServiceCloneRunInput}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdClone>>, {projectId: string;runId: string;data: ServiceCloneRunInput}> = (props) => {
          const {projectId,runId,data} = props ?? {};

          return  postProjectsProjectIdRunsRunIdClone(projectId,runId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostProjectsProjectIdRunsRunIdCloneMutationResult = NonNullable<Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdClone>>>
    export type PostProjectsProjectIdRunsRunIdCloneMutationBody = ServiceCloneRunInput
    export type PostProjectsProjectIdRunsRunIdCloneMutationError = AxiosError<JSONAPIErrorResponse>

    /**
 * @summary Clone a specific project run
 */
export const usePostProjectsProjectIdRunsRunIdClone = <TError = AxiosError<JSONAPIErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdClone>>, TError,{projectId: string;runId: string;data: ServiceCloneRunInput}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdClone>>,
        TError,
        {projectId: string;runId: string;data: ServiceCloneRunInput},
        TContext
      > => {

      const mutationOptions = getPostProjectsProjectIdRunsRunIdCloneMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Generates a fire burn visualisation
 * @summary Generate a visualisation
 */
export const postProjectsProjectIdRunsRunIdGeneratevisualisation = (
    projectId: string,
    runId: string,
    serviceGenerateVisualisationInput: ServiceGenerateVisualisationInput, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AppOutputResponse>> => {
    
    return axios.post(
      `/projects/${projectId}/runs/${runId}/generatevisualisation`,
      serviceGenerateVisualisationInput,options
    );
  }



export const getPostProjectsProjectIdRunsRunIdGeneratevisualisationMutationOptions = <TError = AxiosError<JSONAPIErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdGeneratevisualisation>>, TError,{projectId: string;runId: string;data: ServiceGenerateVisualisationInput}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdGeneratevisualisation>>, TError,{projectId: string;runId: string;data: ServiceGenerateVisualisationInput}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdGeneratevisualisation>>, {projectId: string;runId: string;data: ServiceGenerateVisualisationInput}> = (props) => {
          const {projectId,runId,data} = props ?? {};

          return  postProjectsProjectIdRunsRunIdGeneratevisualisation(projectId,runId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostProjectsProjectIdRunsRunIdGeneratevisualisationMutationResult = NonNullable<Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdGeneratevisualisation>>>
    export type PostProjectsProjectIdRunsRunIdGeneratevisualisationMutationBody = ServiceGenerateVisualisationInput
    export type PostProjectsProjectIdRunsRunIdGeneratevisualisationMutationError = AxiosError<JSONAPIErrorResponse>

    /**
 * @summary Generate a visualisation
 */
export const usePostProjectsProjectIdRunsRunIdGeneratevisualisation = <TError = AxiosError<JSONAPIErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdGeneratevisualisation>>, TError,{projectId: string;runId: string;data: ServiceGenerateVisualisationInput}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdGeneratevisualisation>>,
        TError,
        {projectId: string;runId: string;data: ServiceGenerateVisualisationInput},
        TContext
      > => {

      const mutationOptions = getPostProjectsProjectIdRunsRunIdGeneratevisualisationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Begins a run which is in a ready state.
 * @summary Execute a run
 */
export const postProjectsProjectIdRunsRunIdStart = (
    projectId: string,
    runId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AppRunResponse>> => {
    
    return axios.post(
      `/projects/${projectId}/runs/${runId}/start`,undefined,options
    );
  }



export const getPostProjectsProjectIdRunsRunIdStartMutationOptions = <TError = AxiosError<JSONAPIErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdStart>>, TError,{projectId: string;runId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdStart>>, TError,{projectId: string;runId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdStart>>, {projectId: string;runId: string}> = (props) => {
          const {projectId,runId} = props ?? {};

          return  postProjectsProjectIdRunsRunIdStart(projectId,runId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostProjectsProjectIdRunsRunIdStartMutationResult = NonNullable<Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdStart>>>
    
    export type PostProjectsProjectIdRunsRunIdStartMutationError = AxiosError<JSONAPIErrorResponse>

    /**
 * @summary Execute a run
 */
export const usePostProjectsProjectIdRunsRunIdStart = <TError = AxiosError<JSONAPIErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdStart>>, TError,{projectId: string;runId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdStart>>,
        TError,
        {projectId: string;runId: string},
        TContext
      > => {

      const mutationOptions = getPostProjectsProjectIdRunsRunIdStartMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Accepts a run which has completed as the primary accepted run for the scenario type.
 * @summary Accept a run
 */
export const postProjectsProjectIdRunsRunIdAccept = (
    projectId: string,
    runId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AppRunResponse>> => {
    
    return axios.post(
      `/projects/${projectId}/runs/${runId}/accept`,undefined,options
    );
  }



export const getPostProjectsProjectIdRunsRunIdAcceptMutationOptions = <TError = AxiosError<JSONAPIErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdAccept>>, TError,{projectId: string;runId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdAccept>>, TError,{projectId: string;runId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdAccept>>, {projectId: string;runId: string}> = (props) => {
          const {projectId,runId} = props ?? {};

          return  postProjectsProjectIdRunsRunIdAccept(projectId,runId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostProjectsProjectIdRunsRunIdAcceptMutationResult = NonNullable<Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdAccept>>>
    
    export type PostProjectsProjectIdRunsRunIdAcceptMutationError = AxiosError<JSONAPIErrorResponse>

    /**
 * @summary Accept a run
 */
export const usePostProjectsProjectIdRunsRunIdAccept = <TError = AxiosError<JSONAPIErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdAccept>>, TError,{projectId: string;runId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdAccept>>,
        TError,
        {projectId: string;runId: string},
        TContext
      > => {

      const mutationOptions = getPostProjectsProjectIdRunsRunIdAcceptMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Unaccept a Annual Works run which has completed as the primary accepted run for the scenario type.
 * @summary Unccept a run
 */
export const postProjectsProjectIdRunsRunIdUnaccept = (
    projectId: string,
    runId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AppRunResponse>> => {
    
    return axios.post(
      `/projects/${projectId}/runs/${runId}/unaccept`,undefined,options
    );
  }



export const getPostProjectsProjectIdRunsRunIdUnacceptMutationOptions = <TError = AxiosError<JSONAPIErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdUnaccept>>, TError,{projectId: string;runId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdUnaccept>>, TError,{projectId: string;runId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdUnaccept>>, {projectId: string;runId: string}> = (props) => {
          const {projectId,runId} = props ?? {};

          return  postProjectsProjectIdRunsRunIdUnaccept(projectId,runId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostProjectsProjectIdRunsRunIdUnacceptMutationResult = NonNullable<Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdUnaccept>>>
    
    export type PostProjectsProjectIdRunsRunIdUnacceptMutationError = AxiosError<JSONAPIErrorResponse>

    /**
 * @summary Unccept a run
 */
export const usePostProjectsProjectIdRunsRunIdUnaccept = <TError = AxiosError<JSONAPIErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdUnaccept>>, TError,{projectId: string;runId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdUnaccept>>,
        TError,
        {projectId: string;runId: string},
        TContext
      > => {

      const mutationOptions = getPostProjectsProjectIdRunsRunIdUnacceptMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Mark an Impact Analysis Run which has completed as passing RFS's QA.

Only runs belonging to BFRMP and ITC projects have QA statuses at this time.

 * @summary Mark IAR as passing RFS's QA
 */
export const postProjectsProjectIdRunsRunIdQaRfsPass = (
    projectId: string,
    runId: string,
    serviceRunQAPassInput: ServiceRunQAPassInput, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AppRunResponse>> => {
    
    return axios.post(
      `/projects/${projectId}/runs/${runId}/qa/rfs/pass`,
      serviceRunQAPassInput,options
    );
  }



export const getPostProjectsProjectIdRunsRunIdQaRfsPassMutationOptions = <TError = AxiosError<JSONAPIErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdQaRfsPass>>, TError,{projectId: string;runId: string;data: ServiceRunQAPassInput}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdQaRfsPass>>, TError,{projectId: string;runId: string;data: ServiceRunQAPassInput}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdQaRfsPass>>, {projectId: string;runId: string;data: ServiceRunQAPassInput}> = (props) => {
          const {projectId,runId,data} = props ?? {};

          return  postProjectsProjectIdRunsRunIdQaRfsPass(projectId,runId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostProjectsProjectIdRunsRunIdQaRfsPassMutationResult = NonNullable<Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdQaRfsPass>>>
    export type PostProjectsProjectIdRunsRunIdQaRfsPassMutationBody = ServiceRunQAPassInput
    export type PostProjectsProjectIdRunsRunIdQaRfsPassMutationError = AxiosError<JSONAPIErrorResponse>

    /**
 * @summary Mark IAR as passing RFS's QA
 */
export const usePostProjectsProjectIdRunsRunIdQaRfsPass = <TError = AxiosError<JSONAPIErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdQaRfsPass>>, TError,{projectId: string;runId: string;data: ServiceRunQAPassInput}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdQaRfsPass>>,
        TError,
        {projectId: string;runId: string;data: ServiceRunQAPassInput},
        TContext
      > => {

      const mutationOptions = getPostProjectsProjectIdRunsRunIdQaRfsPassMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Mark an Impact Analysis Run which has completed as failing RFS's QA.

Optionally, all siblings can be also be rejected (e.g. if there is an issue with the
underlying Fire Behaviour Run).

If an accepted run is rejected, it will lose its accepted status. This will cause downstream
runs to be archived as per normal.

Only runs belonging to BFRMP and ITC projects have QA statuses at this time.

 * @summary Mark IAR as failing RFS's QA
 */
export const postProjectsProjectIdRunsRunIdQaRfsReject = (
    projectId: string,
    runId: string,
    serviceRunQARejectInput: ServiceRunQARejectInput, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AppRunsListResponse>> => {
    
    return axios.post(
      `/projects/${projectId}/runs/${runId}/qa/rfs/reject`,
      serviceRunQARejectInput,options
    );
  }



export const getPostProjectsProjectIdRunsRunIdQaRfsRejectMutationOptions = <TError = AxiosError<JSONAPIErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdQaRfsReject>>, TError,{projectId: string;runId: string;data: ServiceRunQARejectInput}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdQaRfsReject>>, TError,{projectId: string;runId: string;data: ServiceRunQARejectInput}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdQaRfsReject>>, {projectId: string;runId: string;data: ServiceRunQARejectInput}> = (props) => {
          const {projectId,runId,data} = props ?? {};

          return  postProjectsProjectIdRunsRunIdQaRfsReject(projectId,runId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostProjectsProjectIdRunsRunIdQaRfsRejectMutationResult = NonNullable<Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdQaRfsReject>>>
    export type PostProjectsProjectIdRunsRunIdQaRfsRejectMutationBody = ServiceRunQARejectInput
    export type PostProjectsProjectIdRunsRunIdQaRfsRejectMutationError = AxiosError<JSONAPIErrorResponse>

    /**
 * @summary Mark IAR as failing RFS's QA
 */
export const usePostProjectsProjectIdRunsRunIdQaRfsReject = <TError = AxiosError<JSONAPIErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdQaRfsReject>>, TError,{projectId: string;runId: string;data: ServiceRunQARejectInput}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdQaRfsReject>>,
        TError,
        {projectId: string;runId: string;data: ServiceRunQARejectInput},
        TContext
      > => {

      const mutationOptions = getPostProjectsProjectIdRunsRunIdQaRfsRejectMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Mark an Impact Analysis Run which has completed as passing NPWS's QA.

See `.../qa/rfs/pass` for details.

 * @summary Mark IAR as passing NPWS's QA
 */
export const postProjectsProjectIdRunsRunIdQaNpwsPass = (
    projectId: string,
    runId: string,
    serviceRunQAPassInput: ServiceRunQAPassInput, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AppRunResponse>> => {
    
    return axios.post(
      `/projects/${projectId}/runs/${runId}/qa/npws/pass`,
      serviceRunQAPassInput,options
    );
  }



export const getPostProjectsProjectIdRunsRunIdQaNpwsPassMutationOptions = <TError = AxiosError<JSONAPIErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdQaNpwsPass>>, TError,{projectId: string;runId: string;data: ServiceRunQAPassInput}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdQaNpwsPass>>, TError,{projectId: string;runId: string;data: ServiceRunQAPassInput}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdQaNpwsPass>>, {projectId: string;runId: string;data: ServiceRunQAPassInput}> = (props) => {
          const {projectId,runId,data} = props ?? {};

          return  postProjectsProjectIdRunsRunIdQaNpwsPass(projectId,runId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostProjectsProjectIdRunsRunIdQaNpwsPassMutationResult = NonNullable<Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdQaNpwsPass>>>
    export type PostProjectsProjectIdRunsRunIdQaNpwsPassMutationBody = ServiceRunQAPassInput
    export type PostProjectsProjectIdRunsRunIdQaNpwsPassMutationError = AxiosError<JSONAPIErrorResponse>

    /**
 * @summary Mark IAR as passing NPWS's QA
 */
export const usePostProjectsProjectIdRunsRunIdQaNpwsPass = <TError = AxiosError<JSONAPIErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdQaNpwsPass>>, TError,{projectId: string;runId: string;data: ServiceRunQAPassInput}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdQaNpwsPass>>,
        TError,
        {projectId: string;runId: string;data: ServiceRunQAPassInput},
        TContext
      > => {

      const mutationOptions = getPostProjectsProjectIdRunsRunIdQaNpwsPassMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Mark an Impact Analysis Run which has completed as failing NPWS's QA.

See `.../qa/rfs/reject` for details.

 * @summary Mark IAR as failing NPWS's QA
 */
export const postProjectsProjectIdRunsRunIdQaNpwsReject = (
    projectId: string,
    runId: string,
    serviceRunQARejectInput: ServiceRunQARejectInput, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AppRunsListResponse>> => {
    
    return axios.post(
      `/projects/${projectId}/runs/${runId}/qa/npws/reject`,
      serviceRunQARejectInput,options
    );
  }



export const getPostProjectsProjectIdRunsRunIdQaNpwsRejectMutationOptions = <TError = AxiosError<JSONAPIErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdQaNpwsReject>>, TError,{projectId: string;runId: string;data: ServiceRunQARejectInput}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdQaNpwsReject>>, TError,{projectId: string;runId: string;data: ServiceRunQARejectInput}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdQaNpwsReject>>, {projectId: string;runId: string;data: ServiceRunQARejectInput}> = (props) => {
          const {projectId,runId,data} = props ?? {};

          return  postProjectsProjectIdRunsRunIdQaNpwsReject(projectId,runId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostProjectsProjectIdRunsRunIdQaNpwsRejectMutationResult = NonNullable<Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdQaNpwsReject>>>
    export type PostProjectsProjectIdRunsRunIdQaNpwsRejectMutationBody = ServiceRunQARejectInput
    export type PostProjectsProjectIdRunsRunIdQaNpwsRejectMutationError = AxiosError<JSONAPIErrorResponse>

    /**
 * @summary Mark IAR as failing NPWS's QA
 */
export const usePostProjectsProjectIdRunsRunIdQaNpwsReject = <TError = AxiosError<JSONAPIErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdQaNpwsReject>>, TError,{projectId: string;runId: string;data: ServiceRunQARejectInput}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postProjectsProjectIdRunsRunIdQaNpwsReject>>,
        TError,
        {projectId: string;runId: string;data: ServiceRunQARejectInput},
        TContext
      > => {

      const mutationOptions = getPostProjectsProjectIdRunsRunIdQaNpwsRejectMutationOptions(options);

      return useMutation(mutationOptions);
    }
    